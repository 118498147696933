// init

import 'bootstrap'
import '../js/slicknav'
import '../js/cookies'
// import '../js/map'
import $ from 'jquery';
jQuery = $;
window.jQuery = $;
window.$ = $;
require('owl.carousel')
// window.AOS = require('aos/dist/aos')
require('@fancyapps/fancybox')
require('@fortawesome/fontawesome-pro')
// window.WebFont = require('webfontloader')
// WebFont.load({
//     google: {
//         families: [ 'Quicksand:600,700' ]
//     },
//     active: () => {
//         sessionStorage.fontsLoaded = true
//     }
// });
import '../sass/main.scss'

// functions

function scrollBanner($this, $strength) {
        if (!$strength) $strength = 6;
        var scrollPos = $(window).scrollTop() - $($this).offset().top;
        if ( $(window).width() > 1013 ) { // moment to responsive
            $($this).css({
                "background-position": "center " + (40 - (scrollPos / $strength)) + "%"
            });
        }
}

function flexmenuwrap(){
    var docViewTop = $(window).scrollTop();
    var docViewBottom = 0;
    var element = $(".wraptop");
    var headerheight = element.outerHeight(true);
    if (docViewTop > docViewBottom) {
        if ( $(window).width() > 1013 ) { // moment to responsive
            element.not("scrolling").addClass("scrolling");
        }else{
            element.removeClass("scrolling");
        }
    } else {
        element.removeClass("scrolling");
    }
}

// jquerybody

$(document).ready(function(){

    $(".navigation").slicknav({
        beforeOpen: function(e) {

            if(e.hasClass('slicknav_btn')){
                $('body').addClass('overflow-hidden');
            }
        },
        beforeClose: function(e) {
            if(e.hasClass('slicknav_btn')){
                $('body').removeClass('overflow-hidden');
            }
        }
    });
    // AOS.init({disable: 'mobile'});


    $('.owl-recentproject').owlCarousel({
        loop:true,
        autoplayHoverPause:true,
        items:1,
        nav:true,
        dots:true,
        navText: ["<i class='fal fa-angle-left'></i>","<i class='fal fa-angle-right'></i>"],
        margin:10
  })

    $('.gallery, [data-fancybox="images"]').fancybox({
      helpers: {
          overlay: {
              locked: false
          }
      },
      buttons: [
          "zoom",
          //"share",
          "slideShow",
          "fullScreen",
          //"download",
          "thumbs",
          "close"
      ],
      margin : [44,0,22,0],
      thumbs : {
          autoStart   :true,
          axis        : 'x'
      }
  });

    $(window).scroll(function() {
        flexmenuwrap();
        if ($(".wraphome__container").length) scrollBanner(".wraphome__container",6);
    });

    $(window).resize(function() {
        flexmenuwrap();
    });
    flexmenuwrap();
    if ($(".wraphome__container").length) scrollBanner(".wraphome__container",6);

    // obsługa animacji labela przy wypełnieniu inputów
    let $contactForm = $('form');
    $contactForm.on('keyup', 'input, textarea', function (event) {
        if ($(this).val().length > 0) {
            $(this).addClass('filled')
        } else {
            $(this).removeClass('filled')
        }
    });

    //obsługa walidacji bootstrapowej formularzy
    (function() {
        'use strict';
        window.addEventListener('load', function() {
            // Fetch all the forms we want to apply custom Bootstrap validation styles to
            var forms = document.getElementsByClassName('needs-validation');
            // Loop over them and prevent submission
            var validation = Array.prototype.filter.call(forms, function(form) {
                form.addEventListener('submit', function(event) {
                    if (form.checkValidity() === false) {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                    form.classList.add('was-validated');
                }, false);
            });
        }, false);
    })();

    // wyszukiwarka pracowników na team.html
    if ($(".wrapteam__searchinput").length) $(".wrapteam__searchinput").keyup(function () {
        var entered = $(this).val();
        $(".searchresoult").each(function () {
            var text = $(this).html();
            if(entered !="")
            {
                if(text.indexOf(entered) > -1)
                {
                    if (!$(this).hasClass("d-flex")) $(this).addClass("d-flex");
                    if ($(this).hasClass("d-none")) $(this).removeClass("d-none");
                }else
                {
                    if (!$(this).hasClass("d-none")) $(this).addClass("d-none");
                    if ($(this).hasClass("d-flex")) $(this).removeClass("d-flex");
                }
            }else{
                if (!$(this).hasClass("d-flex")) $(".searchresoult").addClass("d-flex");
                if ($(this).hasClass("d-none")) $(this).removeClass("d-none");
            }

        });
    });

});

